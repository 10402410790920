// External
import clsx from "clsx";
import { MouseEvent, useContext } from "react";

// Internal
import { CameraContext } from "@/contexts/CameraContext";
import ArrowLeft from "@/public/images/arrow-left.svg";
import ArrowRight from "@/public/images/arrow-right.svg";
import { MenuItemProps } from "./types";

const MenuItem = ({ index, item, setIsMenuOpen }: MenuItemProps) => {
  const { currentMenuItemIndex, is404, isScrolling, navigate } =
    useContext(CameraContext);
  const slug = item.internalLink.slug;
  const isActive = index === currentMenuItemIndex;
  const isPrev =
    index === currentMenuItemIndex - 1 ||
    (index === 3 && currentMenuItemIndex === 0);
  const isNext =
    index === currentMenuItemIndex + 1 ||
    (index === 0 && currentMenuItemIndex === 3);

  return (
    <a
      key={item.id}
      className={clsx(
        "flex items-center gap-x-4 font-body text-l leading-[2.5625rem] transition-opacity hover:underline md:gap-x-2 md:text-m md:leading-[1.75rem]",
        {
          "pointer-events-none opacity-50": isActive || isScrolling,
        },
      )}
      href={`/${slug}`}
      onClick={(e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        setIsMenuOpen(false);

        navigate(index);
      }}
    >
      <span
        className={clsx(
          "relative hidden h-5 w-5 rounded-[2px] border border-white transition-colors duration-300 md:block",
          {
            "bg-white": isActive,
          },
        )}
      >
        <ArrowLeft
          className={clsx(
            "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-opacity duration-300",
            {
              "opacity-0": is404 || !isPrev,
            },
          )}
        />

        <ArrowRight
          className={clsx(
            "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-opacity duration-300",
            {
              "opacity-0": is404 || !isNext,
            },
          )}
        />
      </span>

      {item.title}

      <span className="flex h-5 w-5 items-center justify-center rounded-[2px] border border-white md:hidden [&>svg]:-rotate-45">
        <ArrowRight />
      </span>
    </a>
  );
};

export default MenuItem;
