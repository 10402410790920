"use client";

// External
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import Marquee from "react-fast-marquee";

// Internal
import ArrowRight from "@/public/images/arrow-right-large.svg";
import Logo from "@/public/images/logo.svg";
import { columnsClass, headingClasses } from "@/utils/classes";

const Arrow = () => (
  <span className="hidden h-10 w-10 items-center justify-center rounded-[4px] border-2 border-dark transition-colors duration-300 group-hover:bg-dark md:flex [&>svg]:-rotate-45 [&_path]:fill-dark group-hover:[&_path]:fill-white">
    <ArrowRight />
  </span>
);

const Footer = () => {
  const footerRef = useRef<HTMLElement>(null);
  const [footerHeight, setFooterHeight] = useState(0);

  const updateFooterHeight = () => {
    if (footerRef.current) {
      setFooterHeight(footerRef.current.offsetHeight);
    }
  };

  useEffect(() => {
    updateFooterHeight();

    window.addEventListener("resize", updateFooterHeight);

    return () => {
      window.removeEventListener("resize", updateFooterHeight);
    };
  }, []);

  const linkClass =
    "group flex gap-x-6 items-center text-[1.5rem] leading-[1.75rem] md:text-[2.25rem] md:leading-[2.5625rem]	underline";

  const stringToLoop = [
    "さよなら",
    "tschüss",
    "الوداع",
    "adiós",
    "안녕",
    "doei",
    "再見",
    "bye",
    "अलविदा",
    "ciao",
  ];

  return (
    <>
      <footer
        ref={footerRef}
        className="fixed bottom-0 z-0 w-full items-end bg-white after:content-['']"
      >
        <div className="px-6 pb-12 pt-24 md:px-8 md:pb-24 md:pt-36">
          <div className={clsx(columnsClass, "items-end !justify-start")}>
            <div className="flex w-full flex-col gap-y-6 text-dark md:w-8/12">
              <span className="text-[1.5rem] leading-[1.75rem] md:text-[2.25rem]">
                Neem contact met mij op
              </span>

              <a
                className={linkClass}
                href="https://www.linkedin.com/in/stein-bergervoet-4a1160203/"
                target="_blank"
              >
                LinkedIn
                <Arrow />
              </a>

              <a className={linkClass} href="tel:+31624473592" target="_blank">
                + 31 6 24473592
                <Arrow />
              </a>

              <a
                className={linkClass}
                href="mailto:contact@steinbergervoet.nl"
                target="_blank"
              >
                contact@steinbergervoet.nl
                <Arrow />
              </a>
            </div>

            <div className="w-6/12 md:w-4/12">
              <Logo className="h-auto w-full" preserveAspectRatio="none"></Logo>
            </div>
          </div>
        </div>

        <div className="h-28 w-full border-t-4 border-red py-6 md:h-40 md:py-8">
          <Marquee
            className="!overflow-visible"
            pauseOnHover
            direction="left"
            speed={global.window ? window.innerWidth / 12 : 0}
          >
            {stringToLoop.map((str, index) => (
              <span
                key={index}
                className={clsx(
                  headingClasses.xxxl,
                  "ml-12 whitespace-nowrap text-red",
                )}
              >
                {str}
              </span>
            ))}
          </Marquee>
        </div>
      </footer>

      <style jsx global>{`
        main {
          margin-bottom: ${footerHeight}px;
        }
      `}</style>
    </>
  );
};

export default Footer;
