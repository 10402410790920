import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import * as THREE from "three";

const ResizeHandler = () => {
  const { camera } = useThree();

  useEffect(() => {
    const handleResize = () => {
      if (camera instanceof THREE.PerspectiveCamera) {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [camera]);

  return null;
};

export default ResizeHandler;
