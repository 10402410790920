"use client";

// External
import clsx from "clsx";
import { MouseEvent, useContext, useEffect, useRef, useState } from "react";

// Internal
import { CameraContext } from "@/contexts/CameraContext";
import { MenuContext } from "@/contexts/MenuContext";

// Siblings
import MenuItem from "./components/MenuItem";
import { MenuItem as MenuItemType } from "./types";

const Header = () => {
  const headerRef = useRef<HTMLElement>(null);
  const [scrollPosition, setScrollPosition] = useState(
    global.window ? window.scrollY : 0,
  );
  const [scrollDirection, setScrollDirection] = useState<"up" | "down">("down");
  const [hideMenu, setHideMenu] = useState(false);
  const [isBgTransparent, setIsBgTransparent] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isScrolling, navigate } = useContext(CameraContext);
  const { menuItems } = useContext(MenuContext);

  useEffect(() => {
    const onScroll = () => {
      const currentScrollPosition = window.scrollY;

      setScrollDirection(
        currentScrollPosition < scrollPosition ? "up" : "down",
      );
      setScrollPosition(currentScrollPosition);
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [scrollPosition]);

  useEffect(() => {
    if (headerRef.current) {
      setHideMenu(
        scrollPosition > headerRef.current.clientHeight &&
          scrollDirection === "down",
      );
    }
  }, [headerRef, scrollDirection, scrollPosition]);

  useEffect(() => {
    if (headerRef.current) {
      setIsBgTransparent(scrollPosition <= headerRef.current.clientHeight);
    }
  }, [headerRef, scrollPosition]);

  useEffect(() => {
    if (isMenuOpen) {
      document.documentElement.classList.add("menu-open");
    } else {
      document.documentElement.classList.remove("menu-open");
    }
  }, [isMenuOpen]);

  return (
    <header
      ref={headerRef}
      className={clsx(
        "fixed top-0 z-50 flex w-screen flex-col gap-y-12 overflow-hidden border-b-2 border-b-white bg-dark p-6 transition-all duration-300 lg:block lg:h-auto lg:p-8",
        {
          "-translate-y-full": hideMenu && !isMenuOpen,
          "lg:border-b-transparent lg:bg-transparent": isBgTransparent,
          "h-screen": isMenuOpen,
          "h-[86px]": !isMenuOpen,
        },
      )}
    >
      <div className="flex items-center justify-between">
        <a
          className={clsx(
            "font-heading text-l font-black tracking-[-0.25px] text-white transition-opacity",
            {
              "pointer-events-none opacity-50": isScrolling,
            },
          )}
          href="/"
          onClick={(e: MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();

            navigate(0);
          }}
        >
          sb.
        </a>

        <div className="hidden flex-col lg:flex">
          <span className="white font-heading text-[1rem] leading-none">
            Front-end developer
          </span>

          <span className="white font-heading text-[1rem] leading-none">
            gevestigd in Utrecht
          </span>
        </div>

        <button
          className="relative font-heading text-[1.5rem] leading-none before:absolute before:-inset-4 before:content-[''] lg:hidden"
          type="button"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? "sluit menu" : "menu"}
        </button>
      </div>

      <nav
        className={clsx(
          "transition-opacity duration-300 lg:pointer-events-auto lg:absolute lg:left-1/2 lg:top-1/2 lg:block lg:-translate-x-1/2 lg:-translate-y-1/2 lg:opacity-100",
          {
            "pointer-events-none opacity-0": !isMenuOpen,
          },
        )}
      >
        <ul className="mb-0 ml-0 flex list-none flex-col gap-8 lg:flex-row">
          {menuItems.map((item: MenuItemType, index: number) => (
            <li className="overflow-hidden" key={item.id}>
              <MenuItem
                index={index}
                item={item}
                setIsMenuOpen={setIsMenuOpen}
              />
            </li>
          ))}
        </ul>
      </nav>

      <span
        className={clsx(
          "white font-heading text-[1rem] leading-none transition-opacity duration-300 lg:hidden",
          {
            "opacity-0": !isMenuOpen,
          },
        )}
      >
        Front-end developer gevestigd in Utrecht
      </span>
    </header>
  );
};

export default Header;
