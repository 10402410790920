"use client";

// External
import { useProgress } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import clsx from "clsx";
import { usePathname } from "next/navigation";
import { useContext, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Vector3 } from "three";

// Siblings
import { CameraContext } from "@/contexts/CameraContext";
import AsciiRenderer from "./components/AsciiRenderer";
import CameraControls from "./components/CameraControls";
import Light from "./components/Light";
import Model from "./components/Model";
import NavigationArrows from "./components/NavigationArrows";
import ResizeHandler from "./components/ResizeHandler";

const LogoScene = () => {
  const { progress } = useProgress();
  const pathname = usePathname();
  const { currentMenuItemIndex, is404, isRotating } = useContext(CameraContext);
  const [isLoading, setIsLoading] = useState(true);
  const { ref, inView } = useInView();

  useEffect(() => {
    if (progress === 100) {
      setIsLoading(false);
    }
  }, [progress]);

  const hideLogoScene =
    pathname.startsWith("/blogs/") || pathname.startsWith("/projects/");

  return (
    !hideLogoScene && (
      <section className="relative z-10 h-screen w-screen overflow-x-hidden bg-dark">
        <div
          ref={ref}
          className={clsx("h-full w-full transition-opacity duration-300", {
            "opacity-0": !inView || isLoading,
          })}
        >
          <>
            <Canvas
              camera={{
                fov: 50,
                aspect: global.window
                  ? window.innerWidth / window.innerHeight
                  : 1,
                far: 5,
              }}
              dpr={0.25}
              frameloop={inView ? "always" : "never"}
              shadows={true}
            >
              <Light />

              <Model
                path={"/models/logo.glb"}
                position={new Vector3(0, 0, 3.2)}
                show={(currentMenuItemIndex === 0 && !is404) || isRotating}
              />

              <Model
                path="/models/projects.glb"
                position={new Vector3(1.8, 0, 5)}
                show={currentMenuItemIndex === 1 || isRotating}
              />

              <Model
                path="/models/blogs.glb"
                position={new Vector3(0, 0, 6.8)}
                show={currentMenuItemIndex === 2 || isRotating}
              />

              <Model
                path="/models/contact.glb"
                position={new Vector3(-1.8, 0, 5)}
                show={currentMenuItemIndex === 3 || isRotating}
              />

              <Model
                path={"/models/404.glb"}
                position={new Vector3(0, 0, 3.2)}
                show={is404}
              />

              <AsciiRenderer bgColor="#181818" />

              <CameraControls />

              <ResizeHandler />
            </Canvas>

            <NavigationArrows />
          </>
        </div>
      </section>
    )
  );
};

export default LogoScene;
