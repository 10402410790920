// External
import clsx from "clsx";
import { MouseEvent, useContext } from "react";

// Internal
import { CameraContext } from "@/contexts/CameraContext";
import { MenuContext } from "@/contexts/MenuContext";
import ArrowLeft from "@/public/images/arrow-left-large.svg";
import ArrowRight from "@/public/images/arrow-right-large.svg";

const NavigationArrows = () => {
  const { currentMenuItemIndex, is404, isScrolling, navigate } =
    useContext(CameraContext);
  const { menuItems, menuItemsCount } = useContext(MenuContext);
  const prevMenuItemIndex =
    (currentMenuItemIndex - 1 + menuItemsCount) % menuItemsCount;
  const nextMenuItemIndex = (currentMenuItemIndex + 1) % menuItemsCount;
  const prevMenuItem = menuItems[prevMenuItemIndex];
  const nextMenuItem = menuItems[nextMenuItemIndex];

  const arrowClass = clsx(
    "group absolute top-1/2 flex h-10 w-10 -translate-y-1/2 items-center justify-center rounded-[4px] border-2 border-white transition-colors hover:bg-white",
    {
      "opacity-50": isScrolling,
      "opacity-0 pointer-events-none": is404,
    },
  );

  return (
    <>
      <a
        className={clsx(arrowClass, "left-6 md:left-8")}
        href={prevMenuItem.internalLink.slug}
        onClick={(e: MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();

          navigate(prevMenuItemIndex);
        }}
      >
        <span className="group-hover:[&_path]:fill-dark">
          <ArrowLeft />
        </span>

        <span className="sr-only">
          Navigate to the {prevMenuItem.title} page
        </span>
      </a>

      <a
        className={clsx(arrowClass, "right-6 md:right-8")}
        href={nextMenuItem.internalLink.slug}
        onClick={(e: MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();

          navigate(nextMenuItemIndex);
        }}
      >
        <span className="group-hover:[&_path]:fill-dark">
          <ArrowRight />
        </span>

        <span className="sr-only">
          Navigate to the {nextMenuItem.title} page
        </span>
      </a>
    </>
  );
};

export default NavigationArrows;
