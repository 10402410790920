export const sectionClass = "relative z-10 px-6 md:px-8 py-24 md:py-36";

export const columnsClass =
  "-ml-6 flex flex-wrap justify-center gap-y-12 md:gap-y-24 md:-ml-8 [&>*]:pl-6 md:[&>*]:pl-8";

export const headingClasses = {
  xxxl: "text-xxl md:text-xxxl leading-none",
  xxl: "text-xl md:text-xxl leading-none",
  xl: "text-l md:text-xl leading-none",
  l: "text-m md:text-l leading-none",
};
