import { useThree } from "@react-three/fiber";
import { memo, useEffect, useRef } from "react";
import * as THREE from "three";
import React from "react";

const Light = () => {
  const lightTargetRef = useRef<THREE.Object3D>(null);
  const directionalLightsRef = useRef<THREE.DirectionalLight[]>([]);
  const { scene } = useThree();
  const lightPositions: [number, number, number][] = [
    [5, 5, 5],
    [-5, 5, -5],
  ];

  useEffect(() => {
    if (lightTargetRef.current) {
      directionalLightsRef.current.forEach((light) => {
        if (light) light.target = lightTargetRef.current!;
      });
    }
  }, [scene]);

  return (
    <>
      {lightPositions.map((position, index) => (
        <directionalLight
          key={index}
          ref={(el) => {
            if (el) {
              directionalLightsRef.current[index] = el;
            }
          }}
          position={position}
          intensity={0.6}
          castShadow
          shadow-mapSize-width={256}
          shadow-mapSize-height={256}
          shadow-camera-near={0.1}
          shadow-camera-far={5}
          shadow-camera-left={-5}
          shadow-camera-right={5}
          shadow-camera-top={5}
          shadow-camera-bottom={-5}
        />
      ))}

      <primitive
        object={new THREE.Object3D()}
        position={[0, 0, 5]}
        ref={lightTargetRef}
      />
    </>
  );
};

export default memo(Light);
