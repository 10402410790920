// External
import { useThree } from "@react-three/fiber";
import { gsap } from "gsap";
import { useContext, useEffect } from "react";

// Internal
import { CameraContext } from "@/contexts/CameraContext";
import { MenuContext } from "@/contexts/MenuContext";

const CameraControls = () => {
  const { camera } = useThree();
  const {
    currentMenuItemIndex,
    currentRotation,
    is404,
    isRotating,
    setCurrentMenuItemIndex,
    setIsRotating,
    targetRotation,
  } = useContext(CameraContext);
  const { menuItemsCount } = useContext(MenuContext);

  useEffect(() => {
    const onKeydown = (e: KeyboardEvent): void => {
      const target = e.target as HTMLElement;
      const isFormElement =
        target.tagName === "INPUT" ||
        target.tagName === "TEXTAREA" ||
        target.tagName === "SELECT" ||
        target.isContentEditable;

      if (is404 || isFormElement || isRotating) return;

      if (e.code === "KeyA" || e.code === "ArrowLeft") {
        setIsRotating(true);

        setCurrentMenuItemIndex(
          (currentMenuItemIndex - 1 + menuItemsCount) % menuItemsCount,
        );
      } else if (e.code === "KeyD" || e.code === "ArrowRight") {
        setIsRotating(true);

        setCurrentMenuItemIndex((currentMenuItemIndex + 1) % menuItemsCount);
      }
    };

    window.addEventListener("keydown", onKeydown);

    return () => {
      window.removeEventListener("keydown", onKeydown);
    };
  }, [
    camera,
    currentMenuItemIndex,
    is404,
    isRotating,
    menuItemsCount,
    setCurrentMenuItemIndex,
    setIsRotating,
  ]);

  useEffect(() => {
    gsap.to(currentRotation, {
      current: targetRotation,
      duration: 0.5,
      transitionTimingFunction: "ease.in.out",
      onUpdate: () => {
        camera.rotation.y = currentRotation.current;
        camera.updateProjectionMatrix();
      },
      onComplete: () => {
        setIsRotating(false);
      },
    });
  }, [camera, currentRotation, setIsRotating, targetRotation]);

  return null;
};

export default CameraControls;
