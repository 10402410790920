import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useMemo } from "react";
import { AsciiEffect } from "three-stdlib";

// Siblings
import { AsciiRendererProps } from "./types";

export default function AsciiRenderer({
  bgColor = "black",
  fgColor = "white",
}: AsciiRendererProps) {
  const { size, gl, scene, camera } = useThree();

  const effect = useMemo(() => {
    const effect = new AsciiEffect(gl, " .:-+*=%@#", {
      invert: false,
      color: false,
      resolution: 0.175,
    });

    effect.domElement.style.position = "absolute";
    effect.domElement.style.top = "0px";
    effect.domElement.style.left = "0px";
    effect.domElement.style.pointerEvents = "none";

    return effect;
  }, [gl]);

  useEffect(() => {
    effect.domElement.style.color = fgColor;
    effect.domElement.style.backgroundColor = bgColor;
  }, [fgColor, bgColor, effect]);

  useEffect(() => {
    if (gl.domElement.parentNode) {
      gl.domElement.style.opacity = "0";
      gl.domElement.parentNode.appendChild(effect.domElement);
    }

    return () => {
      if (gl.domElement.parentNode) {
        gl.domElement.style.opacity = "1";
        gl.domElement.parentNode.removeChild(effect.domElement);
      }
    };
  }, [effect, gl]);

  useEffect(() => {
    effect.setSize(size.width, size.height);
  }, [effect, size]);

  useFrame(() => {
    effect.render(scene, camera);
  });

  return null;
}
