"use client";

// External
import { createContext, ReactNode } from "react";

// Internal
import { MenuItem } from "@/components/Header/types";

export const MenuContext = createContext({
  menuItems: [] as MenuItem[],
  menuItemsCount: 4,
});

const MenuProvider = ({
  children,
  menuItems,
}: {
  children: ReactNode;
  menuItems: MenuItem[];
}) => (
  <MenuContext.Provider
    value={{
      menuItems,
      menuItemsCount: menuItems.length,
    }}
  >
    {children}
  </MenuContext.Provider>
);

export default MenuProvider;
