// External
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { memo, useEffect, useMemo, useRef } from "react";
import * as THREE from "three";

// Siblings
import { ModelProps } from "./types";

const Model = ({ path, position, show = true }: ModelProps) => {
  const { scene } = useGLTF(path);
  const groupRef = useRef<THREE.Group | null>(null);
  const modelRef = useRef<THREE.Object3D | null>(null);

  useFrame((_, delta) => {
    if (groupRef.current) {
      // 22.5deg
      groupRef.current.rotation.y += 0.392699082 * delta;
    }
  });

  useEffect(() => {
    if (modelRef.current) {
      const box = new THREE.Box3().setFromObject(modelRef.current);
      const center = new THREE.Vector3();
      box.getCenter(center);
      modelRef.current.position.sub(center);

      modelRef.current.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.receiveShadow = true;
        }
      });
    }
  }, [show]);

  const processedScene = useMemo(() => {
    const tempScene = scene.clone();
    tempScene.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.receiveShadow = true;
      }
    });
    return tempScene;
  }, [scene]);

  return (
    show && (
      <group ref={groupRef} position={position}>
        <primitive ref={modelRef} object={processedScene} />
      </group>
    )
  );
};

export default memo(
  Model,
  (prevProps, nextProps) => prevProps.show === nextProps.show,
);
