"use client";

import clsx from "clsx";
// External
import gsap from "gsap";
import { useEffect, useRef, useState } from "react";

export const MouseFollower = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [cursorStyle, setCursorStyle] = useState<string>("");

  useEffect(() => {
    const handleMousemove = (e: MouseEvent) => {
      const { clientX: x, clientY: y } = e;

      gsap.to(ref.current, {
        x,
        y,
        duration: 0.3,
        ease: "ease.in.out",
      });
    };

    const handleMouseover = (e: MouseEvent): void => {
      const targetElem = e.target as Element | null;
      const cursorElement = targetElem?.closest("a, [data-cursor]");

      if (!cursorElement) {
        setCursorStyle("");

        return;
      }

      setCursorStyle(cursorElement.getAttribute("data-cursor") || "link");
    };

    document.addEventListener("mousemove", handleMousemove);
    document.addEventListener("mouseover", handleMouseover);

    return () => {
      document.removeEventListener("mousemove", handleMousemove);
      document.removeEventListener("mouseover", handleMouseover);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={clsx(
        "pointer-events-none fixed left-0 top-0 z-50 hidden -translate-x-1/2 -translate-y-1/2 rounded-full bg-white mix-blend-difference transition-[height,width] lg:block",
        {
          "h-6 w-6": cursorStyle === "",
          "h-3 w-3": cursorStyle === "link",
        },
      )}
    ></div>
  );
};

export default MouseFollower;
