"use client";

// External
import { usePathname, useRouter } from "next/navigation";
import {
  createContext,
  MutableRefObject,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

// Internal
import { MenuContext } from "@/contexts/MenuContext";

export const CameraContext = createContext({
  currentMenuItemIndex: 0,
  currentRotation: { current: 0 } as MutableRefObject<number>,
  is404: false,
  isRotating: false,
  isScrolling: false,
  navigate: (nextMenuItemIndex: number) => {},
  setCurrentMenuItemIndex: (currentMenuItemIndex: number) => {},
  setIs404: (is404: boolean) => {},
  setIsRotating: (isRotating: boolean) => {},
  targetRotation: 0,
});

const CameraProvider = ({ children }: { children: ReactNode }) => {
  const pathname = usePathname();
  const router = useRouter();
  const { menuItems } = useContext(MenuContext);
  const currentRotation = useRef(0);
  const prevMenuItemIndex = useRef(0);
  const isFirstRender = useRef(true);
  const [is404, setIs404] = useState(false);
  const [isRotating, setIsRotating] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [currentMenuItemIndex, setCurrentMenuItemIndex] = useState(0);
  const [targetRotation, setTargetRotation] = useState(0);

  const navigate = (newMenuItemIndex: number): void => {
    if (newMenuItemIndex === currentMenuItemIndex || isRotating) return;

    setIs404(false);
    setIsRotating(true);

    setCurrentMenuItemIndex(newMenuItemIndex);
  };

  const rotate = (): void => {
    const increment = Math.PI / 2;

    setTargetRotation(
      currentRotation.current -
        increment * (currentMenuItemIndex - prevMenuItemIndex.current),
    );
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      return;
    }

    // 404
    if (currentMenuItemIndex === 5) {
      setTargetRotation(0);

      return;
    }

    const currentMenuItem = menuItems[currentMenuItemIndex];
    const slug = currentMenuItem.internalLink.slug;

    if (window.scrollY !== 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });

      setIsScrolling(true);

      const handleScroll = () => {
        if (window.scrollY === 0) {
          window.removeEventListener("scroll", handleScroll);

          setIsScrolling(false);

          rotate();

          prevMenuItemIndex.current = currentMenuItemIndex;

          router.push(`/${slug}`, { scroll: false });
        }
      };

      window.addEventListener("scroll", handleScroll);
    } else {
      rotate();

      prevMenuItemIndex.current = currentMenuItemIndex;

      router.push(`/${slug}`, { scroll: false });
    }
  }, [currentMenuItemIndex]);

  useEffect(() => {
    if (is404) {
      setIsRotating(true);

      setCurrentMenuItemIndex(5);
    }
  }, [is404]);

  useEffect(() => {
    if (pathname === "/") {
      setCurrentMenuItemIndex(0);

      return;
    }

    // Temp fix. Does not work well when navigating to a project or blog from the homepage.
    if (
      (pathname.includes("projecten") || pathname.includes("blogs")) &&
      pathname !== "/projecten" &&
      pathname !== "/blogs"
    ) {
      return;
    }

    const initialMenuItemIndex = menuItems.findIndex(
      (item) => pathname === `/${item.internalLink.slug}`,
    );

    if (initialMenuItemIndex !== -1) {
      setCurrentMenuItemIndex(initialMenuItemIndex);
    } else {
      setCurrentMenuItemIndex(5);
    }
  }, [pathname]);

  return (
    <CameraContext.Provider
      value={{
        currentMenuItemIndex,
        currentRotation,
        is404,
        isRotating,
        isScrolling,
        navigate,
        setCurrentMenuItemIndex,
        setIs404,
        setIsRotating,
        targetRotation,
      }}
    >
      {children}
    </CameraContext.Provider>
  );
};

export default CameraProvider;
